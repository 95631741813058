@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../fonts/playfair-display/PlayfairDisplay-Regular.otf") format("opentype");;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../fonts/playfair-display/PlayfairDisplay-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 35px 20px -20px
}

.pl-18 {
  padding-left: 72px;
}

.pr-22 {
  padding-right: 92px;
}

.fs-contact {
  font-size: calc(4.57142137em - var(--ffsd));
}

.sticky-navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: top 0.3s;
}
.hidden-navbar {
  top: -100px; 
}